<template>
  <div class="row text-center" :style="currentRouteName == 'dashboard' ? '' : ''">
    <div :class="currentRouteName === 'member' ? ' col-lg-4 col-md-6' : ' col-lg-6 col-xs-12'">
      <a href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background">
          <h4 class="widget-content-light">
            <strong>Main Balance EUSD</strong>
          </h4>
        </div>
        <div class="widget-extra-full">
          <span class="h1 animation-expandOpen game-title-2" v-if="Balance">
            {{ Balance["EUSD"] | formatNumber }}</span
          >
        </div>
      </a>
    </div>
    <div :class="currentRouteName === 'member' ? ' col-lg-4 col-md-6' : ' col-lg-6 col-xs-12'">
      <a href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background">
          <h4 class="widget-content-light">
            <strong>Main Balance EBP</strong>
          </h4>
        </div>
        <div class="widget-extra-full">
          <span class="h1 animation-expandOpen game-title-2" v-if="Balance">
            {{ Balance["EBP"] | formatNumber }}</span
          >
        </div>
      </a>
    </div>
    <div :class="currentRouteName == 'member' ? ' col-lg-4 col-md-6' : ' col-lg-6 col-xs-12'">
      <a href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background">
          <h4 class="widget-content-light">
            <strong>Total members</strong>
          </h4>
        </div>
        <div class="widget-extra-full">
          <span class="h1 animation-expandOpen game-title-2" v-if="Total">{{ Total.member }}</span>
        </div>
      </a>
    </div>
    <div :class="currentRouteName == 'dashboard' ? ' col-lg-6 col-md-6' : ' col-lg-6 col-xs-12'">
      <IB></IB>
    </div>
    <div class=" col-lg-6 col-md-6 col-xs-12'" v-if="currentRouteName == 'member'">
      <a href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background">
          <h4 class="widget-content-light">
            <strong>Link Ref</strong>
          </h4>
        </div>
        <div class="widget-extra-full">
          <input type="text" class="input-custom" ref="link-ref" v-model="url" readonly />
          <button
            v-clipboard:copy="url"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            class="button-custom"
          >
            Copy Link
          </button>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IB from "@/components/dashboard/IB";
export default {
  components: {
    IB
  },
  name: "StatisticPanel",
  data: () => ({
    mainBalance: 0,
    totalMember: 0,
    totalSubAccount: 0
  }),
  computed: {
    ...mapGetters({
      Total: "dashboard/Total",
      Balance: "dashboard/Balance",
      Game: "dashboard/Game",
      Info: "dashboard/Info"
    }),
    url() {
      if (this.Game && this.Info) {
        return "https://system.123betnow.net/signup?sponsor=" + this.Info.id;
      }
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    onCopy: function(e) {
      alert("You just copied: " + e.text);
    },
    onError: function(e) {
      alert("Failed to copy texts");
    }
  },
  filters: {
    formatNumber(value) {
      if (!value) return 0;
      return new Intl.NumberFormat().format(value);
    }
  }
};
</script>
<style scoped>
.button-custom {
  height: 30px;
  background: #c8973b;
  border-top-right-radius: 5px;
  border: 0 none !important;
  border-bottom-right-radius: 5px;
  color: white;
  max-width: 40%;
  margin: 0 !important;
  padding: 0 5px;
  font-size: 15px;
}
.input-custom {
  width: 60%;
  height: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 0 none !important;
  text-align: center;
  font-size: 15px;
  padding: 0;
}
.widget {
  min-height: 155px;
}
.widget-extra-full {
  display: flex;
  justify-content: center;
  align-content: center;
}
.game-title-2 {
  color: #ffa300;
  font-weight: 800;
}
</style>
