<template>
  <div class="row text-center" style="display: flex; flex-direction: column">
    <div class="col-lg-12">
      <a href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background">
          <h4 class="widget-content-light">
            <strong>Member Agency (IB)</strong>
          </h4>
        </div>
        <div class="widget-extra-full member-ib">
          <div class="img-div" v-if="Agency.PackageImage">
            <img :src="Agency.PackageImage" alt="" />
          </div>
          <div class="detail-div" v-if="Agency.PackageName">
            <span>{{ Agency.PackageName }}</span>
            <button class="btn btn-success mx-2" @click="openListPackage()">
              Buy Agency
            </button>
          </div>
        </div>
      </a>
    </div>
    <!-- <div class="col-lg-12">
      <a href="javascript:void(0)" class="widget widget-hover-effect2">
        <div class="widget-extra themed-background">
          <h4 class="widget-content-light">
            <strong>Agency Statistics</strong>
          </h4>
        </div>
        <div class="widget-extra-full" style="flex-direction: column" v-if="Agency.Static">
          <div class="game">
            <div class="left-content">
              <div class="game-title">Total Income Agency</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.PendingLastWeek }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">Total Income IB</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.PendingThisWeek }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">Total Trade</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.TotalTrade }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">Volume Trade</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.VolumeTrade }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">Volume Trade F1</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.Branch_Trade[1] }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">Volume Trade F2</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.Branch_Trade[2] }}</div>
            </div>
          </div>
          <div class="game">
            <div class="left-content">
              <div class="game-title">Volume Trade F3</div>
            </div>
            <div class="right-content">
              <div class="game-title">{{ Agency.Static.Branch_Trade[3] }}</div>
            </div>
          </div>
        </div>
      </a>
    </div> -->
    <v-modal
      name="listPackage"
      :width="'100%'"
      :height="'auto'"
      :adaptive="true"
      :styles="'overflow-y: auto;max-width:900px;max-height:85vh;right:0;margin:auto'"
    >
      <div class="title">List Agency</div>
      <form id="form-deposit" class="form-horizontal" style="padding: 20px;">
        <div class="row">
          <div
            v-for="(list, id) in Agency.AllPackage"
            :key="list.id"
            class="col-lg-3 col-md-6 col-sm-6"
          >
            <div class="list-agency">
              <div class="pricingTable-header">
                <h3 class="title">{{ list.name }}</h3>
              </div>
              <div class="icon">
                <img :src="list.image" />
              </div>
              <div class="price-value">
                <span class="amount">${{ list.price }}</span>
              </div>
              <!-- <ul class="pricing-content">
                  <li>50GB Disk Space</li>
              </ul> -->
              <div class="pricingTable-signup">
                <button
                  v-if="list.can_buy == 1"
                  @click="onBuyAgency(list.id)"
                  type="button"
                  class="btn-success"
                  :class="list.price == 0 ? 'opacity-0' : ''"
                >
                  Buy Agency
                </button>
                <button v-else type="button" class="btn-danger" disabled>
                  Buy Agency
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </v-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "IB",
  data: () => ({
    mainBalance: 0,
    totalMember: 0,
    totalSubAccount: 0,
    listPackage: ""
  }),
  computed: {
    ...mapGetters({
      Agency: "dashboard/Agency"
    })
  },
  methods: {
    onBuyAgency: async function(id) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      this.$store.dispatch("dashboard/req_buyAgency", {
        token: token,
        id: id
      });
    },
    openListPackage() {
      this.$modal.show("listPackage");
    }
  },
  filters: {
    formatNumber(value) {
      if (!value) return 0;
      return new Intl.NumberFormat().format(value);
    }
  }
};
</script>
<style scoped>
.member-ib {
  display: flex;
}
.opacity-0 {
  opacity: 0;
}
.list-agency {
  background: #fff;
  text-align: center;
  margin-bottom: 15px;
  padding: 20px 0 35px;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.3);
}
.list-agency .icon {
  width: 120px;
  margin: 0 auto 15px;
  min-height: 140px;
}
.list-agency .icon img {
  width: 100%;
}
.list-agency .title {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  background: #fff0;
  margin: 0;
}
.list-agency .price-value {
  color: #000;
  padding: 0 15px;
  margin: 0 0 20px;
}
.list-agency .price-value .amount {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  color: #f89923;
}
.list-agency .price-value .duration {
  font-size: 30px;
  font-weight: 300;
  text-transform: lowercase;
}
.list-agency .pricing-content {
  text-align: left;
  padding: 25px 15px;
  margin: 0 0 30px;
  border: 1px solid #e7e7e7;
  border-left: none;
  border-right: none;
  list-style: none;
  display: inline-block;
}
.list-agency .pricing-content li {
  color: #777;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  padding: 0 0 0 40px;
  margin: 0 0 12px;
  position: relative;
}
.list-agency .pricing-content li:last-child {
  margin-bottom: 0;
}
.list-agency .pricing-content li:before {
  content: "\f00c";
  color: #fff;
  background: #f89923;
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  line-height: 24px;
  width: 23px;
  height: 23px;
  border-radius: 50px;
  position: absolute;
  top: 1px;
  left: 0;
}
.list-agency .pricing-content li.disable:before {
  content: "\f00d";
  background: #ed1925;
}
.list-agency .pricingTable-signup button {
  color: #fff;
  background: linear-gradient(#72bc4b, #54a445);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 5px;
  border: none;
  display: inline-block;
  transition: all 0.3s ease;
}
.list-agency .pricingTable-signup button:disabled {
  background: darkred;
  cursor: not-allowed;
  pointer-events: all !important;
}
.list-agency .pricingTable-signup a:hover {
  text-shadow: 4px 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.15);
}
.list-agency .pricingTable-header {
  background: linear-gradient(
    to right,
    #8dc53c,
    #e1e530 20px,
    #e1e530 13px,
    #8dc53c 38px,
    #8dc53c 40%,
    #9bbf3b 60%
  );
}
.pricingTable .pricing-content li:before {
  background: #54a445;
}
.pricingTable .pricing-content li.disable:before {
  background: #ed1925;
}
.pricingTable-header {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 0;
  margin: 0 20px 20px 0;
  clip-path: polygon(0 0, 0 100%, 85% 100%, 100% 50%, 85% 0);
}
.img-div {
  flex: 45%;
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.img-div img {
  max-width: 100px;
  max-height: 140px;
}
.detail-div {
  flex: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
  align-self: center;
  font-size: 30px;
  font-weight: 800;
  color: #ffa300;
}
.button-custom {
  height: 30px;
  background: #c8973b;
  border-top-right-radius: 5px;
  border: 0 none !important;
  border-bottom-right-radius: 5px;
  color: white;
  max-width: 40%;
  margin: 0 !important;
  padding: 0 5px;
  font-size: 15px;
}
.input-custom {
  width: 60%;
  height: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 0 none !important;
  text-align: center;
  font-size: 15px;
  padding: 0;
}
.widget {
  min-height: 155px;
}
.widget-extra-full {
  display: flex;
  justify-content: center;
  align-content: center;
}
.game-title-2 {
  color: #ffa300;

  font-weight: 800;
}
.mx-2 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.game {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  justify-self: center;

  padding-bottom: 5px;

  width: 100%;
}

.right-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  padding-right: 15px;
}
.right-content .group-button .btn {
  min-width: 90px;
}
.left-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  align-self: center;
  padding-left: 15px;
  flex-direction: column;
}

@media (max-width: 500px) {
  .game {
    flex-direction: column;
    border-bottom: 2px solid #c8973b;
  }
}
.game-title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.game-balance {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}
</style>
